import { invokeApi } from "../../bl_libs/invokeApi";

export const courses_list_api = async (page_slug) => {
  const requestObj = {
    path: `api/course/${page_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_course_api = async (data) => {
  const requestObj = {
    path: `api/course`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_course_api = async (data, course_id) => {
  const requestObj = {
    path: `api/course/${course_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_course_api = async (course_id) => {
  const requestObj = {
    path: `api/course/${course_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
