import { Chip, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import {
  delete_announcements_user_api,
  get_announcement_users_api,
} from "../../DAL/Announcements/Announcements";
import { Link, useParams } from "react-router-dom";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";

export default function AnnouncementsUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const { announcement_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [announcementsData, setAnnouncementsData] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [announcementUsers, setAnnouncementUsers] = useState([]);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "name", label: "Name" },
    { id: "user_type", label: "User Type", className: "text-capitalize" },
    {
      id: "is_seen",
      label: "Is Seen",
      renderData: (row) => {
        let is_seen = "No";
        let color = "error";

        if (row.is_seen) {
          is_seen = "Yes";
          color = "success";
        }

        return (
          <Chip
            label={is_seen}
            size="small"
            color={color}
            className="general_mui_chip"
          />
        );
      },
    },
    { id: "action", label: "Action", type: "action" },
  ];

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_announcements_user_api(
      announcementsData._id,
      selectedObject._id
    );
    if (result.code === 200) {
      setAnnouncementUsers((old) =>
        old.filter((user) => user._id !== selectedObject._id)
      );
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_announcement_detail = async () => {
    const result = await get_announcement_users_api(announcement_id);
    if (result.code === 200) {
      setAnnouncementsData(result.announcement);
      setAnnouncementUsers(result.announcement.announcement_users);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_announcement_detail();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="back-button-title mb-4">
                <div class="back-button-box">
                  <Link to={`/announcements`}>
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                </div>
                <div className="table-title">{announcementsData.title}</div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={announcementUsers}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this user?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
