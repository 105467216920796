import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  add_training_lesson_api,
  update_training_lesson_api,
} from "../../DAL/TrainingLesson/TrainingLesson";

export default function AddOrUpdateTrainingLesson({
  onCloseDrawer,
  setTrainingLesson,
  formType,
  selectedObject,
  trainingLesson,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    english_sentence: "",
    pronunciation: "",
    urdu_sentence: "",
    status: true,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      english_sentence: state.english_sentence,
      pronunciation: state.pronunciation,
      urdu_sentence: state.urdu_sentence,
      status: state.status,
    };

    const result =
      formType === "ADD"
        ? await add_training_lesson_api(formData)
        : await update_training_lesson_api(formData, state._id);
    if (result.code === 200) {
      if (formType === "ADD") {
        setTrainingLesson((old) => [result.training_lesson, ...old]);
      } else {
        const newState = trainingLesson.map((obj) => {
          if (obj._id === state._id) {
            return result.training_lesson;
          }
          return obj;
        });
        setTrainingLesson(newState);
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (formType === "EDIT") {
      setState(selectedObject);
    }
  }, [formType, selectedObject]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="english_sentence"
                label="English"
                variant="outlined"
                name="english_sentence"
                value={state.english_sentence}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="pronunciation"
                label="Pronunciation"
                variant="outlined"
                name="pronunciation"
                value={state.pronunciation}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="urdu_sentence"
                label="Urdu"
                variant="outlined"
                name="urdu_sentence"
                value={state.urdu_sentence}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Status"
                  name="status"
                  className="mui-select"
                  value={state.status}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
