import { invokeApi } from "../../bl_libs/invokeApi";

export const todo_lists_api = async (role) => {
  const requestObj = {
    path: `api/todo_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const todo_list_by_user_api = async (data) => {
  const requestObj = {
    path: `api/todo_list/user_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_todo_list_api = async (data) => {
  const requestObj = {
    path: `api/todo_list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_todo_list_api = async (data, note_id) => {
  const requestObj = {
    path: `api/todo_list/${note_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_todo_list_api = async (note_id) => {
  const requestObj = {
    path: `api/todo_list/${note_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
