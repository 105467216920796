import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { baseUrl } from "../../config/config";
import {
  add_web_pages_api,
  update_web_pages_api,
  web_pagess_list_api,
} from "../../DAL/WebPages/web_pages";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";

export default function AddOrUpdateWebPage({
  onCloseDrawer,
  setWebPages,
  formType,
  selectedObject,
  webPages,
  is_template,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [brandLogo, setBrandLogo] = useState("");
  const [state, setState] = useState({
    title: "",
    meta_title: "",
    meta_keyword: "",
    meta_decription: "",
    template: null,
    status: true,
  });

  const handleRemove = () => {
    setPreviews("");
    setBrandLogo("");
  };

  const handleUpload = (event) => {
    setBrandLogo(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("title", state.title);
    formData.append("meta_title", state.meta_title);
    formData.append("meta_keyword", state.meta_keyword);
    formData.append("meta_decription", state.meta_decription);
    formData.append("status", state.status);
    formData.append("is_template", is_template);
    if (brandLogo) {
      formData.append("brand_logo", brandLogo);
    }

    if (!is_template) {
      formData.append("template", state.template._id);
    }

    const result =
      formType === "ADD"
        ? await add_web_pages_api(formData)
        : await update_web_pages_api(formData, state._id);
    if (result.code === 200) {
      let new_web_page = result.web_page;
      new_web_page.template = state.template;
      new_web_page.table_avatar = {
        src: baseUrl + new_web_page.brand_logo,
        alt: new_web_page.title,
      };
      if (formType === "ADD") {
        setWebPages((webPages) => [new_web_page, ...webPages]);
      } else {
        const newState = webPages.map((obj) => {
          if (obj._id === state._id) {
            return new_web_page;
          }
          return obj;
        });
        setWebPages(newState);
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeOthers = (name, value) => {
    setState((old) => {
      return { ...old, [name]: value };
    });
  };

  const get_templates_list = async () => {
    const result = await web_pagess_list_api(true);
    if (result.code === 200) {
      setTemplatesList(result.pages_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (!is_template) {
      get_templates_list();
    }
    if (formType == "EDIT") {
      setState(selectedObject);
      if (selectedObject.brand_logo) {
        setPreviews(baseUrl + selectedObject.brand_logo);
      }
    }
  }, [formType]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            {!is_template && (
              <div className="col-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Choose Template"
                  selectedOption={state.template}
                  setSelectedOption={(e) => {
                    handleChangeOthers("template", e);
                  }}
                  optionsList={templatesList}
                  autoComplete="new-password"
                  name="title"
                  required
                />
              </div>
            )}
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="title"
                label="Title"
                variant="outlined"
                name="title"
                value={state.title}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="meta_title"
                label="Meta Title"
                variant="outlined"
                name="meta_title"
                value={state.meta_title}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="meta_keyword"
                label="Meta Keyword"
                variant="outlined"
                name="meta_keyword"
                value={state.meta_keyword}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Status"
                  name="status"
                  className="mui-select"
                  value={state.status}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12">
              <TextField
                multiline
                rows={4}
                className="mt-4 form-control"
                id="meta_decription"
                label="Meta Decription"
                variant="outlined"
                name="meta_decription"
                value={state.meta_decription}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12 mt-3 d-flex">
              {previews && (
                <span className="upload-file-preview mt-3">
                  <span onClick={handleRemove}>x</span>
                  <img src={previews} />
                </span>
              )}
              {previews == "" && (
                <Tooltip title="Upload Logo">
                  <span className="upload-button mt-2">
                    <input
                      color="primary"
                      accept="image/*"
                      type="file"
                      id="icon-button-file"
                      style={{ display: "none" }}
                      onChange={handleUpload}
                    />
                    <label htmlFor="icon-button-file">
                      <CloudUploadIcon />
                    </label>
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
