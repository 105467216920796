import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { get_min_max_page_value } from "../../utils/constant";
import { quranPagesBaseUrl } from "../../config/config";

export default function HolyQuranIndexPages() {
  const params = useParams();
  const [pageImages, setPageImages] = useState([]);
  const part_index = params.index.replace(/\D/g, ""); // getting part index from params

  useEffect(() => {
    const min_value = get_min_max_page_value(part_index).min;
    const max_value = get_min_max_page_value(part_index).max;

    let pages = [];
    for (let index = min_value; index <= max_value; index++) {
      pages.push(index);
    }
    setPageImages(pages);
  }, []);

  return (
    <div className="container theme-card mt-3 p-5">
      <div class="row pb-5">
        <div class="col-12 pb-3 d-flex">
          <div class="back-button-box">
            <Link to={`/lesson-books/quran-pak`}>
              <i className="fas fa-arrow-left"></i>
            </Link>
          </div>
          <h2>Holy Quran</h2>
        </div>
        <Divider className="sidebar-divider mb-4" />
        {pageImages.map((page, index) => {
          return (
            <div class="col-sm-12 col-md-4 col-lg-2 mb-3 holy-quran-index-pages">
              <Link
                to={`/lesson-books/quran-pak/part-index-${part_index}/page-index-${page}`}
              >
                <img
                  src={`${quranPagesBaseUrl}Para ${part_index}/${page}.jpg`}
                  alt=""
                  className="img-fluid"
                />
              </Link>
              <Link
                to={`/lesson-books/quran-pak/part-index-${part_index}/page-index-${page}`}
              >
                <div class="text-center">
                  <h2>Page No {page}</h2>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
