import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { login_by_admin_api } from "../../DAL/Login/Login";

export default function LoginByAdmin() {
  const {
    setUserInfo,
    START_SOCKET_FORCEFULLY,
    userInfo,
    STOP_SOCKET_FORCEFULLY,
  } = useContentSetting();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();

  const handleSubmit = async () => {
    setIsLoading(true);
    const result = await login_by_admin_api(token);
    if (result.code === 200) {
      if (userInfo?._id) {
        STOP_SOCKET_FORCEFULLY();
      }
      setUserInfo(result.associate);
      localStorage.setItem(`user_data`, JSON.stringify(result.associate));
      localStorage.setItem("token", result.token);
      if (result.associate._id) {
        START_SOCKET_FORCEFULLY(result.associate._id);
        navigate(`/dashboard`);
      }
    } else {
      enqueueSnackbar("Invalid Credentials", { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }
}
