import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import CustomMUITable from "../../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { baseUrl } from "../../../config/config";
import ConfirmationPopup from "../../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../../components/GeneralComponents/CustomDrawer";
import { sidebar_pathname } from "../../../utils/constant";
import {
  courses_list_api,
  delete_course_api,
} from "../../../DAL/WebPages/Course";
import AddOrUpdateCourse from "../../../components/WebPages/Course/AddOrUpdateCourse";

export default function CourseList() {
  const { enqueueSnackbar } = useSnackbar();
  const { page_slug } = useParams();
  const { pathname } = useLocation();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [courses, setCourses] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [pageInfo, setPageInfo] = useState({});

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_courses_list = async () => {
    const result = await courses_list_api(page_slug);
    if (result.code === 200) {
      const data = result.courses.map((course) => {
        return {
          ...course,
          table_avatar: {
            src: baseUrl + course.image,
            alt: course.name,
          },
        };
      });
      setCourses(data);
      setPageInfo(result.web_page);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleOpenImage = (row, index) => {
    window.open(baseUrl + row.image);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Title" },
    {
      id: "table_avatar",
      label: "Image",
      type: "thumbnail",
      className: "reduce-cell-padding cursor-pointer",
      handleClick: handleOpenImage,
    },
    { id: "description", label: "Description" },
    { id: "status", label: "Status", type: "row_status" },
    { id: "action", label: "Action", type: "action" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };
  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_course_api(selectedObject._id);
    if (result.code === 200) {
      const data = courses.filter(
        (course) => course._id !== selectedObject._id
      );
      setCourses(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_courses_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="back-button-title mb-3">
                <div class="back-button-box">
                  <Link to={sidebar_pathname(pathname)}>
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                </div>
                <div className="table-title">{pageInfo.title}</div>
              </div>
              <div className="light-border-bottom d-flex justify-content-between">
                <div className="mb-2 mui-table-heading">
                  <h2>Courses List</h2>
                </div>
                <div className="add--button mb-3">
                  <button onClick={handleOpenDrawer}>Add Course</button>
                </div>
              </div>

              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={courses}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType == "ADD" ? "Add" : "Update"} Course`}
        componentToPassDown={
          <AddOrUpdateCourse
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            courses={courses}
            setCourses={setCourses}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this course?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
