import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  add_todo_list_api,
  update_todo_list_api,
} from "../../DAL/ToDoList/ToDoList";

export default function AddOrUpdateToDoList({
  onCloseDrawer,
  setToDoLists,
  formType,
  selectedObject,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    title: "",
    description: "",
    priority: "normal",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      title: state.title,
      description: state.description,
      priority: state.priority,
    };

    const result =
      formType === "ADD"
        ? await add_todo_list_api(formData)
        : await update_todo_list_api(formData, state._id);
    if (result.code === 200) {
      if (formType === "ADD") {
        setToDoLists((old) => [result.todo_list, ...old]);
      } else {
        setToDoLists((old) => {
          return old.map((obj) => {
            if (obj._id === state._id) {
              return result.todo_list;
            }
            return obj;
          });
        });
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (formType === "EDIT") {
      setState(selectedObject);
    }
  }, [formType, selectedObject]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="title"
                label="Title"
                variant="outlined"
                name="title"
                value={state.title}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="priority">Priority</InputLabel>
                <Select
                  labelId="priority"
                  id="priority"
                  label="Priority"
                  name="priority"
                  className="mui-select"
                  value={state.priority}
                  onChange={handleChange}
                >
                  <MenuItem value="normal">Normal</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="least">Least</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12">
              <TextField
                className="mt-4 form-control"
                id="description"
                label="Description"
                variant="outlined"
                name="description"
                value={state.description}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
