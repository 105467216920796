import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { conversations_list_api } from "../../DAL/Chatting/Chatting";
import { useSnackbar } from "notistack";
import { useContentSetting } from "../../Hooks/ContentSetting";
import Conversations from "../../components/Chatting/Conversations";
import UserCard from "../../components/Chatting/UserCard";
import ChattingMessages from "../../components/Chatting/ChattingMessages";
import {
  messages_list_api,
  read_messages_api,
} from "../../DAL/Messages/Messages";
import { sort_array_based_on_createdAt } from "../../utils/constant";
import { baseUrl } from "../../config/config";
import { logo } from "../../assets";
import { useParams } from "react-router-dom";
import { useClassRoom } from "../../Hooks/ClassRoom";

const sum_unread_messages = (conversation_data, userIdToSum) => {
  let totalUnreadMessages = 0;
  conversation_data.forEach((conversation) => {
    const member = conversation.members.find(
      (member) => member._id === userIdToSum
    );
    if (member) {
      totalUnreadMessages += member.unread_message_count;
    }
  });
  return totalUnreadMessages;
};

export default function Chatting() {
  const { enqueueSnackbar } = useSnackbar();
  const { conversation_id } = useParams();
  const { setCallUser } = useClassRoom();
  const { userInfo, socket, setUnreadMessageCount } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isLoadingFirst, setIsLoadingFirst] = useState(false);
  const [conversationsList, setConversationsList] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [messagesList, setMessagesList] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);

  const handleSelected = (user) => {
    setSelectedConversation(user);
  };

  const scroll_to_bottom_on_message = () => {
    const chat_box = document.getElementById("chat-box-body");
    if (chat_box) {
      if (isLoadingFirst) {
        chat_box.scrollTop = chat_box?.scrollHeight;
      } else {
        chat_box.scroll({ top: chat_box.scrollHeight, behavior: "smooth" });
      }
    }
    setIsLoadingFirst(false);
  };

  const get_conversations_list = async () => {
    const result = await conversations_list_api();
    if (result.code === 200) {
      setConversationsList(result.conversations_list);
      if (conversation_id && result.conversations_list.length > 0) {
        let find_conversation = result.conversations_list.find(
          (chat) => chat._id == conversation_id
        );

        if (find_conversation) {
          setSelectedConversation(find_conversation);
        }
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const get_messages_list = async () => {
    setIsLoadingMessages(true);
    setIsLoadingFirst(true);
    const result = await messages_list_api(selectedConversation._id);
    if (result.code === 200) {
      setMessagesList(result.messages_list);
      read_messages_api(selectedConversation._id); //calling api for read messages
      setConversationsList((chat) => {
        return chat.map((old) => {
          let members = old.members.map((member) => {
            if (member._id == userInfo._id) {
              return {
                ...member,
                unread_message_count: 0,
              };
            }
            return member;
          });

          if (old._id == selectedConversation._id) {
            return {
              ...old,
              members: members,
            };
          }
          return old;
        });
      });
      setIsLoadingMessages(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMessages(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formData = {
      conversation_id: selectedConversation?._id,
      sender_id: userInfo._id,
      message: messageText,
    };
    socket.emit("send_message_from_class", formData);
  };

  const showNotification = (data) => {
    let find_student = data.members.find((user) => user.user_type == "student");
    let find_member = data.members.find((member) => member._id == userInfo._id);
    let notification_title = `${find_member.name} messages to ${find_student.name}`;

    let profile_image = logo;
    if (find_student?.profile_image) {
      profile_image = baseUrl + find_student?.profile_image;
    }
    const notification = new Notification(notification_title, {
      body: data.message.message,
      icon: profile_image,
    });
  };

  const getNotificationPermission = (dataObject) => {
    if (Notification.permission === "granted") {
      showNotification(dataObject);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          showNotification(dataObject);
        }
      });
    }
  };

  const handle_message_receiver = (result) => {
    if (result.code === 200) {
      let members = result.members;
      let conversation_id = result.message.conversation_id;
      let find_member = members.find((member) => member._id == userInfo._id);

      if (find_member && selectedConversation?._id == conversation_id) {
        setMessagesList((old) => [...old, result.message]);
        read_messages_api(selectedConversation._id); //calling api for read messages
      }

      if (
        !selectedConversation ||
        selectedConversation?._id !== conversation_id
      ) {
        getNotificationPermission(result);
      }
      if (result.message.message_type !== "call") {
        setConversationsList((chat) => {
          return chat.map((old) => {
            let members = old.members.map((member) => {
              if (selectedConversation?._id !== old._id) {
                return {
                  ...member,
                  unread_message_count: member.unread_message_count + 1,
                };
              }
              return member;
            });

            if (old._id == conversation_id) {
              return {
                ...old,
                last_message: result.message,
                members: members,
              };
            }
            return old;
          });
        });
      }
      if (result.message.sender_id == userInfo._id) {
        setMessageText("");
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const delete_message_from_class_receiver = (result) => {
    if (result.code === 200) {
      setMessagesList((old) =>
        old.filter((message) => message._id !== result.data.message_id)
      );

      if (result.is_last_message) {
        setConversationsList((chat) => {
          return chat.map((old) => {
            if (old._id == result.data.conversation_id) {
              return {
                ...old,
                last_message: result.last_message,
              };
            } else {
              return old;
            }
          });
        });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_update_message_receiver = (result) => {
    if (result.code === 200) {
      if (selectedConversation?._id == result.data.conversation_id) {
        setMessagesList((messages) => {
          return messages.map((old) => {
            if (old._id == result.last_message._id) {
              return result.last_message;
            } else {
              return old;
            }
          });
        });
      }

      if (result.is_last_message) {
        setConversationsList((chat) => {
          return chat.map((old) => {
            if (old._id == result.data.conversation_id) {
              return {
                ...old,
                last_message: result.last_message,
              };
            } else {
              return old;
            }
          });
        });
      }
      if (result.data.sender_id == userInfo._id) {
        setMessageText("");
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const update_call_status = (values, status) => {
    const { data, chat_message } = values;
    console.log(chat_message, "chat_messagechat_message");
    console.log(values, "valuesvaluesvaluesvaluesvalues");
    let meeting_id = data.meeting_id;
    console.log(meeting_id, "meeting_idmeeting_idmeeting_id");
    setMessagesList((old) => {
      return old.map((message) => {
        if (message.meeting_id == meeting_id) {
          if (status == "ended") {
            return chat_message;
          }
          return {
            ...message,
            call_status: status,
          };
        }
        return message;
      });
    });
  };

  useEffect(() => {
    get_conversations_list();
  }, []);

  useEffect(() => {
    scroll_to_bottom_on_message();
  }, [messagesList]);

  useEffect(() => {
    if (selectedConversation) {
      get_messages_list();
      let conversations_user = selectedConversation?.members.find(
        (user) => user.user_type == "student"
      );
      if (conversations_user) {
        setCallUser(conversations_user._id);
      }
    }
  }, [selectedConversation]);

  useEffect(() => {
    socket.on("send_message_from_class_receiver", (data) => {
      handle_message_receiver(data);
    });
    socket.on("delete_message_from_class_receiver", (data) => {
      delete_message_from_class_receiver(data);
    });
    socket.on("update_message_from_class_receiver", (data) => {
      handle_update_message_receiver(data);
    });
    socket.on("call:ringing", (data) => {
      update_call_status(data, "ringing");
    });
    socket.on("call:accepted", (data) => {
      update_call_status(data, "started");
    });
    socket.on("call:canceled", (data) => {
      update_call_status(data, "canceled");
    });
    socket.on("call:declined", (data) => {
      update_call_status(data, "declined");
    });
    socket.on("call:ended", (data) => {
      update_call_status(data, "ended");
    });
    socket.on("call:no_answered", (data) => {
      update_call_status(data, "no_answered");
    });
    socket.on("call:unavailable", (data) => {
      update_call_status(data, "unavailable");
    });

    return () => {
      socket.off("send_message_from_class_receiver");
      socket.off("update_message_from_class_receiver");
      socket.off("delete_message_from_class_receiver");
      socket.off("call:ringing");
      socket.off("call:accepted");
      socket.off("call:declined");
      socket.off("call:canceled");
      socket.off("call:ended");
      socket.off("call:no_answered");
      socket.off("call:unavailable");
    };
  }, [selectedConversation]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  sort_array_based_on_createdAt(conversationsList);
  setUnreadMessageCount(sum_unread_messages(conversationsList, userInfo._id));

  return (
    <>
      <div className="container pt-4">
        <div className="row">
          <div className="col-4">
            <Conversations
              conversationsList={conversationsList}
              handleSelected={handleSelected}
              selectedConversation={selectedConversation}
            />
          </div>
          <div className="col-8">
            <div className="chatting-section">
              {selectedConversation && (
                <UserCard selectedConversation={selectedConversation} />
              )}
              <ChattingMessages
                messagesList={messagesList}
                isLoadingMessages={isLoadingMessages}
                selectedConversation={selectedConversation}
              />
              {selectedConversation && (
                <form onSubmit={handleSubmit}>
                  <div className="d-flex align-items-center w-100 write-message">
                    <input
                      type="text"
                      placeholder="Write A Message"
                      value={messageText}
                      required
                      onChange={(e) => {
                        setMessageText(e.target.value);
                      }}
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
