import { invokeApi } from "../../bl_libs/invokeApi";

export const image_sliders_list_api = async (page_slug) => {
  const requestObj = {
    path: `api/image_slider/${page_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_image_slider_api = async (data) => {
  const requestObj = {
    path: `api/image_slider`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_image_slider_api = async (data, image_slider_id) => {
  const requestObj = {
    path: `api/image_slider/${image_slider_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_image_slider_api = async (image_slider_id) => {
  const requestObj = {
    path: `api/image_slider/${image_slider_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
