import { invokeApi } from "../../bl_libs/invokeApi";

export const messages_list_api = async (conversation_id) => {
  const requestObj = {
    path: `api/messages/${conversation_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const message_by_meeting_api = async (meeting_id) => {
  const requestObj = {
    path: `api/messages/meeting/${meeting_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const read_messages_api = async (conversation_id) => {
  const requestObj = {
    path: `api/conversations/read_messages/${conversation_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
